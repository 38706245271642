<template>
  <div>
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-xs-10 offset-xs-1 myContainer">
        <h1>Welcome Degenerate</h1>
        <br />
        <p class="text-black">
          MemeTeam.Fyi is dedicated to generating entertainment during this bullsh*t crypto winter. Join us on twitter
          and discord and meet your fellow degenerates. We promise entertainment although probably short lived since our
          projects have no roadmap or utility other than the Lolz.
        </p>
        <p class="text-black">
          Don't ask for utility. Our projects are released in whatever state we decide to release them in. Take it or
          leave it. We won't ask you for money for our sh*t cause we know you're poor already. If you buy a token on
          secondary you probably are the top 0.1% of the biggest of brain of retard. Just don't miss our free mints.
        </p>
        <p class="text-black">
          All projects are provided as CC0. Do with it what you want, we don't care.
        </p>
        <p class="text-black">
          Have some fun you sour-puss. Eth will hit All-Time-Highs again... in 2069.
        </p>
        <p class="text-black">
          Sincerely(or not your choice) Your Masters of Meme
          <br />
          MemeTeam.Fyi
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  }
}

</script>

<style lang="css">

</style>
<template>
  <rect :x="x" :y="y" :width="width" :height="height" :stroke="stroke" :stroke-width="strokeWidth" :fill="fill" stroke-dasharray="1 2 3 4"
  :transform="transform">
    <animate
        attributeName="stroke-dashoffset"
        values="360;0" dur="60s"
        repeatCount="indefinite">
    </animate>
  </rect>
</template>

<script>
export default {
  name: "SvgRect",
  props: ['x','y','width','height','fill', 'stroke', 'strokeWidth','transform']
}
</script>

<style scoped>

</style>
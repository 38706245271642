<template>
  <div>
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-xs-10 offset-xs-1 myContainer">
        <h2>TERMS & CONDITIONS</h2>
        <p class="text-black">
          Job Title Generator is a collection of digital artwork (NFTs) running on the Ethereum network. This website is
          only an interface allowing participants to mint and view the art. Users are entirely responsible for the
          safety and management of their own private Ethereum wallets and validating all transactions and contracts
          generated by this website before approval. Furthermore, as the Job Title Generator smart contract runs on the
          Ethereum network, there is no ability to undo, reverse, or restore any transactions.
        </p>
        <p class="text-black">
          This website and its connected services are provided “as is” and “as available” without warranty of any kind.
          By using this website you are accepting sole responsibility for any and all transactions involving
          MemeTeam.Fyi
          digital collectibles.
        </p>
      </div>
    </div>
    <div class="row vspace">
      <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-xs-10 offset-xs-1 myContainer">
        <h1>OWNERSHIP</h1>
        <p class="text-black">
          i. You Own the NFT. Each <span class="fw-bold">Job Title</span> is an NFT on the Ethereum blockchain. When you mint an NFT, you own the
          underlying completely. Ownership of the NFT is mediated entirely by the Smart Contract and
          the Ethereum Network: at no point may we seize, freeze, or otherwise modify the ownership of any Job Title (NFT).
        </p>
        <p class="text-black">
          ii. Personal Use. Subject to your continued compliance with these Terms, Chainheads Inc grants you a worldwide,
          royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to
          create or use, solely for the following purposes: (i) for your own personal, non-commercial use; (ii) as part
          of a marketplace that permits the purchase and sale of your Job Title / NFT, provided that the marketplace
          cryptographically verifies each Job Title owner’s rights to display the Art for their Job Title to ensure that
          only the actual owner can display the Art; or (iii) as part of a third party website or application that
          permits the inclusion, involvement, or participation of your Job Title, provided that the website/application
          cryptographically verifies each Job Title owner’s rights to display the Art for their Job Title to ensure that
          only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the
          Job Title leaves the website/application.
        </p>
        <p class="text-black">
          iii. Commercial Use. Subject to your continued compliance with these Terms, ChainHeads Inc grants you an
          unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating
          derivative
          works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be the use of the Art
          to
          produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of clarity,
          nothing in this Section will be deemed to restrict you from (i) owning or operating a marketplace that permits
          the use and sale of Job Titles generally, provided that the marketplace cryptographically verifies eachBored
          Ape
          owner’s rights to display the Art for their Job Title to ensure that only the actual owner can display the
          Art;
        </p>
        <p class="text-black">
          (ii) owning or operating a third party website or application that permits the inclusion, involvement, or
          participation of Job Titles generally, provided that the third party website or application cryptographically
          verifies each Job Title owner’s rights to display the Art for theirJob Title to ensure that only the actual
          owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased
          Bored
          Ape leaves the website/application; or (iii) earning revenue from any of the foregoing.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms"
}
</script>

<style scoped>

</style>
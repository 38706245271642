<template>
  <div style="width: 420px; height: 420px;">

    <svg viewBox="0 0 420 420"
         xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="420" height="420" fill="black" />

      <filter id="displacementFilter">
        <feTurbulence type="turbulence" baseFrequency="0.1" seed="42069"
                      numOctaves="0" result="turbulence"/>
        <feDisplacementMap in2="turbulence" in="SourceGraphic"
                           scale="1" xChannelSelector="B" yChannelSelector="G"/>
      </filter>

      <symbol id="megagroup1" filter="url(#displacementFilter)">
<!--        <svg-rect x="150" y="150" width="120" height="120" fill="none" stroke="white" stroke-width="2" stroke-dasharray="1 2 3 4" transform="" />-->

<!--        <svg-rect x="150" y="150" width="120" height="120" fill="none" stroke="white" stroke-width="2" stroke-dasharray="1 2 3 4" transform="rotate(15,210,210)" />-->

<!--        <svg-rect x="150" y="150" width="120" height="120" fill="none" stroke="white" stroke-width="2" stroke-dasharray="1 2 3 4" transform="rotate(75,210,210)" />-->

<!--        <svg-rect x="150" y="150" width="120" height="120" fill="none" stroke="white" stroke-width="2" stroke-dasharray="1 2 3 4" transform="rotate(45,210,210)" />-->

<!--        <svg-rect x="150" y="150" width="120" height="120" fill="none" stroke="white" stroke-width="2" stroke-dasharray="1 2 3 4" transform="rotate(30,210,210)" />-->

<!--        <svg-rect x="150" y="150" width="120" height="120" fill="none" stroke="white" stroke-width="2" stroke-dasharray="1 2 3 2 1" transform="rotate(60,210,210)" />-->

        <svg-circle v-for="(n, i) in step" :duration="getDuration(n)" cx="50%" cy="50%" :r="getRadius(n)" stroke="white" stroke-width="2" fill="none" :stroke-dasharray="dashArray" />
      </symbol>

      <use href="#megagroup1" stroke="white" stroke-width="2" stroke-opacity="100%" />
    </svg>
    <br />
    <button class="btn btn-primary" @click="goNext">Next</button> | <button class="btn btn-primary" @click="goBack">Prev</button><br />
    <input type="text" v-model="dashArray" /> <br />
    <span class="text-white">Step: {{step}}</span>
  </div>
</template>

<script>
import SvgCircle from "@/components/SvgCircle";
import SvgRect from "@/components/SvgRect";
export default {
  name: "ShatterWorld",
  components: {SvgRect, SvgCircle},
  data() {
    return {
      step: 40,
      dashArray: '1 2 3 2 1 10',
      durationStep: 3,
    };
  },
  methods: {
    goNext() {
      this.step++;
    },
    goBack() {
      this.step--;
    },
    getDuration(i) {
      return (15 + this.durationStep*i) + 's';
    },
    getRadius(i) {
      return 5*i;
    }
  }
}
</script>

<style scoped>

</style>
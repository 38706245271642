<template>
  <circle :cx="cx" :cy="cy" :r="r" :stroke="stroke" :stroke-width="strokeWidth" :fill="fill"
          stroke-dasharray="1 2 3 2 1"
  >
    <animate
        attributeName="stroke-dashoffset"
        values="360;0" :dur="duration"
        repeatCount="indefinite">
    </animate>
  </circle>
</template>

<script>
export default {
  name: "SvgCircle",
  props:['cx','cy','r','stroke', 'strokeWidth', 'fill', 'duration']
}
</script>

<style scoped>

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Terms from "@/views/Terms";
import JobTitleGenerator from "@/views/JobTitleGenerator";
import ShatterWorld from "@/views/ShatterWorld";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'MemeTeam - LFG',
    }
  },
  {
    path: '/job-title-generator',
    name: 'JobTitleGenerator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: JobTitleGenerator,
    meta: {
      title: 'MemeTeam - LFG',
    }
  },
  {
    path: '/jt-terms',
    name: 'Terms and Conditions',
    component: Terms,
    meta: {
      title: 'MemeTeam - LFG',
    }
  },
  {
    path: '/shatter-world',
    name: 'ShatterWorld',
    component: ShatterWorld,
    meta: {
      title: 'MemeTeam - LFG',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';

  next();
})

export default router
